import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import OptionGroup from "../components/molecules/option-group";

function DesignOptionGroup() {
  return (
    <div className="design__content">
      <SEO title="Design System - Option Group" />
      <h2>Option Group</h2>
      <div className="design__stories">
        <div className="design__story-section">
          <h5>Option Group</h5>
          <DesignStory
            type="component"
            component={OptionGroup}
            props={{
              options: {
                name: "Size",
                values: ["Small", "Medium", "Large"],
              },
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps
            component={OptionGroup}
            title="Option Group Properties"
          />
        </div>
      </div>
    </div>
  );
}

export default DesignOptionGroup;
